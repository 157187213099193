<template>
  <article>
    <h2 class="title">{{ common.popups.popupLogin.title }}</h2>
    <p class="text">
      {{ common.popups.popupLogin.text }}
    </p>
    <form @submit.prevent="submit">
      <div
        class="input-wrapper"
        :class="{
          invalid: $v.phone.$error || phoneNotFound,
          valid: !$v.phone.$invalid
        }"
      >
        <label class="label" for="phone"> {{ common.fields.phone }}</label>
        <IMaskComponent
          class="input"
          id="phone"
          inputmode="numeric"
          :mask="'+{7}(000)000-00-00'"
          @complete="onComplete"
          ref="input"
          v-model.trim.lazy="phone"
          @blur="onTouchFunc('phone', $event.target.value)"
          @input="backToInit"
        />
        <small v-if="$v.phone.$error || phoneNotFound">{{
          phoneNotFound
            ? common.messages.numberNotFound
            : common.fieldsError.errorPopupPhone
        }}</small>
      </div>
      <button type="submit" class="button" :disabled="disabledBtn">
        {{ common.btnsText.continue }}
      </button>
    </form>
  </article>
</template>

<script>
import { onReset, onTouch } from '@/utils/helperField';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { IMaskComponent } from 'vue-imask';

export default {
  name: 'PopupLogin',
  components: {
    IMaskComponent
  },
  props: ['common', 'phoneNotFound'],
  data() {
    return {
      phone: '',
      disabledBtn: true,
      onResetFunc: onReset,
      onTouchFunc: onTouch
    };
  },
  validations: {
    phone: {
      required,
      minLength: minLength(16),
      maxLength: maxLength(16)
    }
  },
  methods: {
    backToInit() {
      this.onResetFunc('phone');
      this.$emit('clear-field');
    },
    onComplete() {
      this.disabledBtn = false;
    },
    inputFocus() {
      this.$refs.input.$el.focus();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('request-login', this.phone, this.phoneNotFound);
    }
  },
  mounted() {
    this.inputFocus();
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

form button {
  margin-top: 20px;
}

.title {
  @extend %popup-title;
  margin: 20px 0 10px;
}

.text {
  @extend %popup-text;
  margin: 10px 0 20px;
}

.label {
  @extend %form-label;
}

.input {
  @extend %form-input;
  margin: 5px 0 0;
}

.button {
  @extend %btn;
  @extend %btn-green;
  max-width: 100%;
  margin-top: 35px;
}
</style>
