import { changePath } from '@/utils/commonFuncs';

// check access path
const checkAccessPath = function(getme) {
  if (window.location.pathname.includes('contact')) {
    if (
      !Object.prototype.hasOwnProperty.call(getme.contactData, 'id') &&
      !getme.contactData.phone.length
    ) {
      changePath('poll/primary');
    }
  } else if (window.location.pathname.includes('passport')) {
    if (!Object.prototype.hasOwnProperty.call(getme.contactData, 'id')) {
      changePath('poll/contact');
    }
  } else if (
    window.location.pathname.includes('card') ||
    window.location.pathname.includes('before')
  ) {
    if (!Object.prototype.hasOwnProperty.call(getme.contactData, 'id')) {
      changePath('poll/primary');
    } else if (
      Object.prototype.hasOwnProperty.call(getme.contactData, 'id') &&
      !Object.prototype.hasOwnProperty.call(getme.passportData, 'id')
    ) {
      changePath('poll/passport');
    }
  }
};

export { checkAccessPath };
