<template>
  <div class="step-count">
    <a
      href="/poll/primary"
      class="step-count__item"
      :class="{ passed: step >= 1, disabled: step <= 1 }"
      :tabindex="step <= 1 ? -1 : 0"
    >
      1
    </a>
    <a
      href="/poll/contact"
      class="step-count__item"
      :class="{ passed: step >= 2, disabled: step <= 2 }"
      :tabindex="step <= 2 ? -1 : 0"
    >
      2
    </a>
    <a
      href="/poll/passport"
      class="step-count__item"
      :class="{ passed: step >= 3, disabled: step <= 3 }"
      :tabindex="step <= 3 ? -1 : 0"
    >
      3
    </a>
    <a
      href="/poll/before"
      class="step-count__item"
      :class="{ passed: step >= 4, disabled: step <= 4 }"
      :tabindex="step <= 4 ? -1 : 0"
    >
      4
    </a>
  </div>
</template>

<script>
export default {
  name: 'StepCount',
  props: ['step']
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.step-count {
  position: absolute;
  display: flex;
  flex-direction: column;

  left: calc(((100% - 970px) / 2) - 100px);

  @media (max-width: 1250px) {
    left: 35px;
  }

  @media (max-width: 990px) {
    display: none;
  }

  &__item {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 20px 55px rgba(39, 40, 48, 0.15);

    font-family: 'Montserrat-Bold', 'Roboto', 'sans-serif';
    font-size: 30px;
    color: $yellow;

    position: relative;
    outline: none;

    transition: all 0.3s;

    &:hover,
    &:focus {
      text-decoration: none;
      transform: scale(1.1);
    }

    &:nth-child(-n + 3) {
      margin-bottom: 80px;
    }

    &::after,
    &::before {
      content: '';
      position: absolute;
      box-sizing: border-box;
      width: 1px;
      height: 40px;
      border: 2px dashed $white;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      bottom: -40px;
    }

    &::before {
      top: -40px;
    }
  }

  .passed {
    color: $green-base;
  }

  .disabled {
    pointer-events: none;
    cursor: default;
  }

  &__item:first-child {
    &::before {
      width: 0;
      height: 0;
      border: none;
    }
  }

  &__item:last-child {
    &::after {
      width: 0;
      height: 0;
      border: none;
    }
  }
}
</style>
