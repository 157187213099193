<template>
  <div :class="['dadata_container', containerClasses]">
    <input
      type="text"
      :maxlength="maxlength"
      :value="value"
      :class="[
        'dadata_input input',
        inputClasses,
        suggestions.length && inputFocused ? 'search' : ''
      ]"
      :id="id"
      :inputmode="inputmode"
      :disabled="disabled"
      :placeholder="placeholder"
      autoComplete="off"
      :ref="id"
      v-imask="mask ? { mask } : null"
      @input="inputHandler"
      @change="changeHandler"
      @focus="focusHandler"
      @blur="blurHandler"
    />
    <div class="dadata_suggestions" v-if="suggestions.length && inputFocused">
      <div
        class="dadata_suggestion"
        v-for="(suggestion, index) in suggestions"
        @mousedown="selectSuggestion(suggestion)"
        :key="`${suggestion.value}_${index}`"
        v-html="markResult(dadataFormatResult(suggestion))"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import debounce from '@/utils/debounce';
import { onComplete } from '@/utils/helperField';
import { IMaskDirective } from 'vue-imask';

export default {
  name: 'VueDadata',
  props: {
    value: { type: String },
    // name: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    containerClasses: { type: String, default: '' }, // стили для контейнера
    inputClasses: { type: String, default: '' }, // стили для поля
    id: { type: String },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean },
    isFocus: { type: Boolean, default: false }, // флаг для установки фокуса на поле при помощи watch (обазяательно иметь props.id)
    // minlength: { type: Number }, // минимальное кол. символов в поле
    maxlength: { type: Number, default: 40 }, // максимальное кол. символов в поле
    colorize: { type: Boolean, default: true }, // подсветка совпадений поля и результатов дадаты
    inputmode: { type: Boolean, default: false }, // быстрый ввод чисового поля с телефона
    mask: { default: null }, // маска для валидации ввода
    regexp: { default: null }, // регулярное выражение для валидации event
    dadataFieldType: { type: String, default: 'NAME' }, // тип данных дадата из массива [NAME, SURNAME, PATRONYMIC, ADDRESS, FMS_UNIT]
    gender: { type: Number, default: null }, // тип гендера анкеты, где 0 (MALE) / 1 (FEMALE) (for FIO request)
    token: {
      type: String,
      default: '45687bf219a5f40ba1f1cac0fa3f18bc9db4298a'
    },
    userIP: { type: String, default: '' }, // IP анкеты, для более точечного поиска dadate
    onBlurHandler: { type: Function, default: null },
    onFocusHandler: { type: Function, default: null },
    onInputHandler: { type: Function, default: null },
    onChangeHandler: { type: Function, default: null }
  },
  data() {
    return {
      inputFocused: false,
      onCompleteFunc: onComplete,
      suggestions: [], // dadata suggestions
      kladr_id: null // for dadata request (get user location)
    };
  },
  directives: {
    imask: IMaskDirective
  },
  mounted() {
    if (this.userIP) {
      this.getKladrId();
    }
  },
  computed: {
    dadataType: function() {
      return this.dadataFieldType.trim().toUpperCase();
    }
  },
  watch: {
    userIP(ip) {
      if (ip) {
        this.getKladrId();
      }
    },
    isFocus(value) {
      if (value) {
        this.onCompleteFunc(this.id);
      }
    }
  },
  methods: {
    blurHandler(event) {
      this.inputFocused = false;

      if (this.onBlurHandler !== null) {
        this.onBlurHandler({ key: this.id, value: event.target.value });
      }
    },
    focusHandler(event) {
      this.inputFocused = true;

      if (this.onFocusHandler !== null) {
        this.onFocusHandler(event);
      }
    },
    inputHandler(event) {
      if (this.regexp !== null && !this.regexp.test(event.target.value)) {
        event.target.value = event.target.value.slice(
          0,
          event.target.value.length - 1
        );
        return false;
      }

      this.$emit('input', event.target.value);
      this.dadataRequest(event.target.value);

      if (this.onInputHandler !== null) {
        this.onInputHandler(event);
      }
    },
    changeHandler(event) {
      this.$emit('input', event.target.value);

      if (this.onChangeHandler !== null) {
        this.onChangeHandler(event);
      }
    },
    dadataRequest(value) {
      let _this = this;

      if (!value.length) {
        _this.suggestions = [];
        return;
      }

      const data = {
        count: 5,
        parts: [_this.dadataType],
        query: value
      };
      let url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/';

      switch (_this.dadataType) {
        case 'NAME':
        case 'SURNAME':
        case 'PATRONYMIC':
          url += 'fio';
          // gender for request
          if (_this.gender !== null) {
            data.gender = _this.gender ? 'FEMALE' : 'MALE';
          }
          break;

        case 'FMS_UNIT':
          url += 'fms_unit';

          // delete parts key
          delete data.parts;
          break;

        case 'ADDRESS':
          url += 'address';

          if (_this.kladr_id !== null && _this.kladr_id !== undefined) {
            data.locations_boost = [{ kladr_id: _this.kladr_id }];
          }
          break;

        default:
          break;
      }

      const options = {
        url: url,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + _this.token
        },
        data: data
      };

      debounce(
        axios(options)
          .then(response => {
            let dadataResult = {};

            if (_this.dadataType === 'ADDRESS') {
              dadataResult = response.data.suggestions.map(suggestion => {
                suggestion.value = _this.makeAddressString(suggestion.data);
                return suggestion;
              });
            } else if (_this.dadataType === 'FMS_UNIT') {
              dadataResult = response.data.suggestions.map(suggestion => {
                suggestion.value = suggestion.data.code;
                return suggestion;
              });
            } else {
              dadataResult = response.data.suggestions;
            }

            _this.suggestions = Object.freeze(dadataResult);
          })
          .catch(error => console.log('error', error)),
        300
      );
    },
    dadataFormatResult(suggestion) {
      let value = '';

      switch (this.dadataType) {
        case 'ADDRESS':
          value = this.makeAddressString(suggestion.data);
          break;

        case 'FMS_UNIT':
          value = suggestion.data.code + ' — ' + suggestion.data.name;
          break;

        case 'SURNAME':
        case 'NAME':
        case 'PATRONYMIC':
          value = suggestion.value;
          break;

        default:
          break;
      }

      return value;
    },
    markResult(value) {
      const markLength = this.value.length;
      if (this.colorize) {
        return `<span class="mark">${value.slice(
          0,
          markLength
        )}</span><span>${value.slice(markLength)}</span>`;
      } else {
        return `<span>${value}</span>`;
      }
    },
    joinAddress(arr) {
      let separator = arguments.length > 1 ? arguments[1] : ', ';

      return arr
        .filter(function(n) {
          return n;
        })
        .join(separator);
    },
    makeAddressString(address) {
      return this.joinAddress([
        this.joinAddress([address.region_type, address.region], ' '),
        this.joinAddress([address.settlement_type, address.settlement], ' '),
        (address.city !== address.region &&
          this.joinAddress([address.city_type, address.city], ' ')) ||
          '',
        this.joinAddress([address.street_type, address.street], ' '),
        this.joinAddress(
          [
            address.house_type,
            address.house,
            address.block_type,
            address.block
          ],
          ' '
        ),
        this.joinAddress([address.flat_type, address.flat], ' ')
      ]);
    },
    selectSuggestion(suggestion) {
      let _this = this;

      _this.$emit('input', suggestion.value);
      _this.suggestions = [];

      // set gender / regionId / settlementId
      _this.$emit('after-select-suggestion', {
        suggestion,
        type: this.dadataFieldType
      });
    },
    getKladrId() {
      if (this.userIP.length) {
        axios({
          url:
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address',
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token ' + this.token
          },
          data: {
            ip: this.userIP
          }
        }).then(response => {
          if (
            Object.prototype.hasOwnProperty.call(response.data.location, 'data')
          ) {
            this.kladr_id = response.data.location.data.kladr_id;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/main.scss';

.dadata_container {
  position: relative;
}

.dadata_input.search {
  border-radius: 10px 10px 0 0;
  border-bottom: none;

  &:focus {
    border-bottom: none;
  }
}
.invalid .dadata_container .dadata_suggestions {
  margin-top: -21px;
  border: 2px solid $invalid;
  border-top: 0px solid $white;
}
.dadata_suggestions {
  position: absolute;
  z-index: 20;
  margin-top: -21px;
  width: 100%;
  background-color: $white;
  border-radius: 0 0 10px 10px;
  padding: 10px 20px 10px;
  border: 2px solid $green-base;
  border-top: none;
  text-decoration: none;
}

.dadata_suggestion {
  position: relative;
  padding: 5px 0;
  cursor: pointer;
  color: transparentize($black-text, 0.4);

  .mark {
    color: $green-base;
  }

  &:hover {
    color: $black-text;

    .mark {
      font-weight: 500;
      color: $btn-green;
    }
  }
}
</style>
