import axios from 'axios';
import doRequestGetme from './doRequestGetme';

const doRequest = function(dataForServer, path) {
  console.log(dataForServer);
  debugger;
  // loader
  this.isLoading = true;

  axios
    .post('/api/' + path, dataForServer)
    .then(response => {
      this.getme.csrf = response.data.csrf;

      if (
        Object.prototype.hasOwnProperty.call(response.data, 'checkPhoneByCode')
      ) {
        this.getme.code_hash = response.data.code_value;

        if (Object.prototype.hasOwnProperty.call(response.data, 'wrongCode')) {
          this.getme.wrongCode = true;
        } else {
          // open Popup
          this.isOpenPopup = true;
          this.popupName = 'sms';
        }

        // loader
        this.isLoading = false;
        return;
      } else {
        this.getme.code_hash = '';
        this.getme.code = '';
        this.getme.wrongCode = false;
      }

      if (response.data.next_step.startsWith('/')) {
        window.location.href = window.location.origin + response.data.next_step;
      } else {
        window.location.href =
          window.location.origin + '/' + response.data.next_step;
      }

      // loader
      this.isLoading = false;
    })
    .catch(error => {
      if (error.response.status === 412) {
        const path = error.response.config.url.split('/').reverse()[0];
        doRequestGetme.call(this, true, path);
      }

      if (error.response.status === 400 || error.response.status === 500) {
        alert(this.config.dictionary.common.messages.errorSendRequest);
        document.location.reload();
      }

      if (error.response.status === 302) {
        document.location.reload();
      }

      // loader
      this.isLoading = false;
      throw new Error(error);
    });
};

export default doRequest;
