<template>
  <article>
    <h2 class="title">{{ common.popups.popupAcceptance.title }}</h2>
    <p class="text" v-html="common.popups.popupAcceptance.text"></p>
  </article>
</template>

<script>
export default {
  name: 'popupAcceptance',
  props: ['common']
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.title {
  @extend %popup-title;
  margin: 20px 0 10px;
}

.text {
  @extend %popup-text;
  margin: 10px 0 20px;
}
</style>
