<template>
  <header class="header" :class="{ shadow: isOpenMenu }">
    <div class="header__wrapper">
      <a href="/">
        <picture>
          <source
            width="200"
            height="40"
            srcset="@/assets/images/new/new_blue_logo.svg"
            media="(min-width: 640px)"
          />
          <img
            width="140"
            height="40"
            src="~@/assets/images/new/new_blue_logo.svg"
            alt="логотип Money"
          />
        </picture>
      </a>
      <button
        class="burger"
        :class="{ new_color: checkLogo }"
        name="burger"
        aria-label="toggle menu bar"
        @click="toggleMenu"
      >
        <div class="img" :class="{ img_close: isOpenMenu }"></div>
      </button>
    </div>
    <transition name="fade">
      <div v-if="isOpenMenu || !isMobile" class="panel">
        <a class="phone" :href="`tel:${config.siteConfig.contactPhone}`">{{
          config.siteConfig.contactPhone
        }}</a>
        <a v-if="isAuth && isMobile" href="/lk" class="cabinet">
          {{ config.dictionary.common.btnsText.lk }}
        </a>
        <a
          v-if="isAuth && isMobile && checkCookie"
          href="/final"
          class="myLoans"
        >
          {{ config.dictionary.common.btnsText.myLoans }}
        </a>
        <a
          href="/poll/primary"
          class="btn-order"
          :target="isNewWindow ? '_blank' : ''"
          @click="goToOffers"
        >
          {{ config.dictionary.common.btnsText.chooseBorrow }}
        </a>

        <PopupHeader
          v-if="isMenu && !isMobile"
          :common="config.dictionary.common"
          :isNewWindow="isNewWindow"
          :goToOffers="goToOffers"
        />
        <button
          v-if="isAuth && !isMobile"
          class="btn-login"
          @click="isMenu = !isMenu"
        >
          {{ loginName }}
        </button>

        <button v-if="!isAuth" class="btn-login" @click="login">
          {{ config.dictionary.common.btnsText.login }}
        </button>
      </div>
    </transition>
  </header>
</template>

<script>
import PopupHeader from '@/components/app/Popups/PopupHeader';
import Cookies from 'js-cookie';

export default {
  name: 'Header',
  props: {
    isOpenMenu: Boolean,
    isMobile: Boolean,
    getme: Object,
    config: Object,
    isGreen: Boolean,
    isNewWindow: Boolean
  },
  components: { PopupHeader },
  data() {
    return {
      isMenu: false
    };
  },
  computed: {
    isAuth() {
      return Object.prototype.hasOwnProperty.call(this.getme.contactData, 'id');
    },
    loginName() {
      return this.getme.contactData.firstname
        ? this.getme.contactData.firstname
        : this.config.dictionary.common.guest;
    },
    checkLogo() {
      return !this.checkCookie && this.isMobile && this.isGreen;
    },
    checkCookie() {
      return (
        Cookies.get('sbg-cpa') !== undefined || Cookies.get('CPA') !== undefined
      );
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu');
    },
    login() {
      this.toggleMenu();
      this.$emit('open-login-popup');
    },
    goToOffers() {
      if (this.isNewWindow) {
        setTimeout(function() {
          window.location.href = 'https://pr.yescash.ru/';
        }, 100);
      } else {
        return -1;
      }
    }
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.isMenu = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.header {
  height: 100%;
  min-height: 70px;
  max-height: 70px;
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;

  img {
    max-width: none;
    height: 100%;
  }

  @media (max-width: 990px) {
    min-height: 70px;

    &.shadow::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 70px;
      left: 0;
      background-color: transparentize($main-black, 0.4);
      z-index: 1;
    }
  }

  .logo {
    display: flex;
    align-content: center;

    img {
      height: 41px;
      width: 260px;

      @media (max-width: 960px) {
        width: 41px;
      }
    }
  }
}

.header__wrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;

  @media (max-width: 990px) {
    width: 100%;
    height: 70px;
    background-color: transparent;
    z-index: 10;
    margin-right: 0;
  }
}

.panel {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  > * {
    margin: 0 10px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 990px) {
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 990px) {
    flex-direction: column;
    max-width: 100%;
    padding: 80px 50px 50px;
    width: 100%;
    background-color: $white;
    z-index: 5;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  }

  .phone {
    font-family: 'Montserrat-SemiBold', 'Roboto', 'sans-serif';
    font-size: 13px;
    line-height: 20px;
    color: $main-black;
    text-decoration: none;
    outline: none;
    position: relative;
    padding-left: 25px;

    @media (max-width: 990px) {
      margin-bottom: 20px;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &::before {
      content: '';
      background-image: url('~@/assets/images/new/icon_phone_header.svg');
      background-size: contain;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0px;
      top: 2px;
    }
  }

  .cabinet {
    @extend %btn;
    @extend %btn-link;
    display: flex;
    justify-content: flex-end;
    max-height: 20px;
    line-height: 20px;
    color: $green-dark;
    max-width: 160px;
    text-decoration: none;
    position: relative;

    @media (max-width: 990px) {
      margin: 20px 0;
      max-width: 190px;
    }

    &:focus,
    &:hover {
      text-decoration: underline;
    }

    &::after {
      content: url('~@/assets/images/icon_lk.svg');
      position: absolute;
      width: 14px;
      height: 14px;
      left: 0px;
      top: 1px;
    }
  }

  .myLoans {
    @extend %btn;
    @extend %btn-link;
    display: flex;
    justify-content: flex-end;
    color: $main-black;
    max-height: 20px;
    line-height: 20px;
    max-width: 120px;
    text-decoration: none;
    position: relative;

    @media (max-width: 990px) {
      max-width: 140px;
      margin: 20px 0;
    }

    &:focus,
    &:hover {
      color: $main-black;
      text-decoration: underline;
    }

    &::after {
      content: url('~@/assets/images/icon_list.svg');
      position: absolute;
      width: 15px;
      height: 14px;
      left: 0px;
      top: 1px;
    }
  }

  .btn-order {
    line-height: 35px;
    @extend %btn-small;
    @extend %btn-link-green;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;

    @media (max-width: 990px) {
      min-height: 60px;
      line-height: 60px;
      max-width: 275px;
      width: 100%;
    }
  }

  .btn-login {
    line-height: 35px;
    @extend %btn-small;
    @extend %btn-gradient;
    width: auto;
    min-width: 100px;
    max-width: 275px;
    border-radius: 10px;

    @media (max-width: 990px) {
      min-height: 60px;
      line-height: 60px;
      width: 100%;
    }
  }
}

.burger {
  display: none;

  @media (max-width: 990px) {
    display: block;
    border: none;
    width: 30px;
    height: 25px;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
  }

  .img {
    position: relative;
    width: 30px;
    height: 4px;
    background-color: $white;
    border-radius: 6px;

    &:after,
    &::before {
      position: absolute;
      content: '';
      width: 30px;
      height: 4px;
      background-color: $white;
      border-radius: 6px;
      left: 0;
      transform-origin: 100%;

      transition: all 0.1s;
    }

    &:after {
      top: -10px;
    }

    &:before {
      bottom: -10px;
    }

    &_close {
      background-color: $white;

      &:after {
        transform-origin: 100%;
        transform: rotate(-45deg);
        top: -11px;
        background-color: $green-dark;
      }

      &:before {
        transform-origin: 100%;
        transform: rotate(45deg);
        bottom: -10px;
        background-color: $green-dark;
      }
    }
  }
}

.index_header {
  .panel {
    .btn-order {
      @extend %btn-transparent;

      @media (max-width: 990px) {
        border: 3px solid $green-dark;
        color: $green-dark;

        &:hover,
        &:focus {
          color: $white;
          background-color: $green-dark;
        }
      }
    }

    .phone {
      color: $white;

      &::before {
        content: '';
        background-image: url('~@/assets/images/new/icon_phone_header_white.svg');
      }

      @media (max-width: 990px) {
        color: $main-black;

        &::before {
          background-image: url('~@/assets/images/new/icon_phone_header.svg');
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  top: -250px;
  border-radius: 0;
}
</style>
