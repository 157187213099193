function onComplete(field, dadata = false) {
  if (dadata) {
    this.$data.focus = field;
  } else {
    this.$refs[field].$el === undefined
      ? this.$refs[field].focus()
      : this.$refs[field].$el.focus();
  }
}

function onReset(field) {
  this.$v[field].$reset();
}

function onTouch(key, value) {
  this[key] = value;
  if (value.length) {
    this.$v[key].$touch();
  }
}

export { onComplete, onReset, onTouch };
