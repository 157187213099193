import axios from 'axios';
import Cookies from 'js-cookie';
import doRequestGetme from './doRequestGetme';
import { setCookiesTimeZone } from '../commonFuncs';

const doRequestFrontConfig = function(path = 'index') {
  // loader
  this.isLoading = true;
  setCookiesTimeZone();

  axios
    .get('/conf/' + path + '-ru.json')
    .then(response => {
      const languages = response.data.languages;
      const frontConfig = response.data.frontConfig;
      const selectLanguage = Cookies.get('selectLanguage')
        ? Cookies.get('selectLanguage')
        : frontConfig.defaultLang;
      const siteConfig = response.data.siteConfig;
      const dictionary = response.data.dictionary;

      if (
        Cookies.get('sbg-cpa') !== undefined ||
        Cookies.get('CPA') !== undefined
      ) {
        siteConfig['comsa'] = '';
        siteConfig['footerText'] = siteConfig['footerTextCpa'];
        siteConfig['textBeforeCard'] = siteConfig['textBeforeCardCpa'];
        siteConfig['textAfterService'] = siteConfig['textAfterServiceCpa'];
        siteConfig['acceptanceAutoPayment'] = '';

        dictionary.common.btnsText['getMoney'] =
          dictionary.common.btnsText['getMoneyCpa'];
        dictionary.common.btnsText['chooseBorrow'] =
          dictionary.common.btnsText['chooseBorrowCpa'];
        // acceptance
        this.getme.acceptance = true;
        this.getme.acceptanceAutoPayment = true;
      }

      this.config = Object.freeze({
        languages,
        frontConfig,
        selectLanguage,
        siteConfig,
        dictionary
      });

      doRequestGetme.call(this);
    })
    .catch(error => {
      // loader
      this.isLoading = false;
      throw new Error(error);
    });
};

export default doRequestFrontConfig;
