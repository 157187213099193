function toggleMenu() {
  this.isOpenMenu = !this.isOpenMenu;
  toggleFreez(this.isOpenMenu);
}

const toggleFreez = bool => {
  if (document.querySelector('.popup-shadow') !== null) {
    return;
  }

  if (bool) {
    document.body.classList.add('body-popup--active');
  } else {
    document.body.classList.remove('body-popup--active');
  }
};

const updateWindowWidth = function() {
  this.widnowWidth = window.innerWidth;
};

export { toggleMenu, toggleFreez, updateWindowWidth };
