<template>
  <div class="icon-load">
    <div class="pulse">
      <b>ЗАГРУЗКА...</b>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
};
</script>

<style lang="scss" scoped>
b {
  text-align: center;
  display: block;
  font-weight: bolder;
  color: #fff;
  font-weight: bold;
  line-height: 150px;
  font-size: 20px;
  text-transform: uppercase;
}

.icon-load {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;

  .pulse {
    width: 100%;
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    animation: radial-pulse 3s infinite;
  }

  .img {
    padding: 10px;
    width: 100%;
    z-index: 100;
    transform-origin: center;
    animation: rotate 1.5s ease-in-out infinite;

    .icon {
      fill: #fbbf4d;
    }
  }

  @keyframes radial-pulse {
    0% {
      background-color: rgba(138, 206, 23, 0);
      box-shadow: 0 0 0 0 rgba(138, 206, 23, 0);
    }

    50% {
      background-color: #fbbf4d;
      box-shadow: 0 0 0 60px #fbbf4d;
    }

    100% {
      background-color: rgba(138, 206, 23, 0);
      box-shadow: 0 0 0 0 rgba(138, 206, 23, 0);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(1deg);
    }

    50% {
      transform: rotate(-1deg);
    }

    100% {
      transform: rotate(1deg);
    }
  }
}

.loader-enter-active,
.loader-leave-active {
  .img {
    animation: scale 1s;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.5);
  }
}
</style>
