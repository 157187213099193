<template>
  <article class="popup" :class="{ multi: checkCookie }">
    <a
      href="/lk"
      class="cabinet"
      :target="isNewWindow ? '_blank' : ''"
      @click="goToOffers"
    >
      {{ common.popups.popupHeader.lk }}
    </a>
    <a
      href="/loans"
      class="myLoans"
      v-if="checkCookie"
      :target="isNewWindow ? '_blank' : ''"
      @click="goToOffers"
    >
      {{ common.popups.popupHeader.myLoans }}
    </a>
  </article>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'PopupHeader',
  props: ['common', 'isNewWindow', 'goToOffers'],
  computed: {
    checkCookie() {
      return (
        Cookies.get('sbg-cpa') !== undefined || Cookies.get('CPA') !== undefined
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.popup {
  position: absolute;
  margin: 0 !important;
  top: 55px;
  right: 0;
  width: 220px;
  padding: 30px 20px;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px 5px 10px 0px rgba(51, 51, 51, 0.15);

  &::after {
    content: '';
    position: absolute;
    right: 40px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid $white;
  }

  .cabinet {
    @extend %btn;
    @extend %btn-link;
    max-height: 20px;
    width: auto;
    line-height: 20px;
    color: $btn-green;
    margin: 0;
    text-decoration: none;
    position: relative;
    padding: 0 0 0 20px;

    @media (max-width: 990px) {
      margin: 30px 0;
    }

    &:focus,
    &:hover {
      color: $btn-green;
      text-decoration: underline;
    }

    &::after {
      content: url('~@/assets/images/icon_lk.svg');
      position: absolute;
      width: 14px;
      height: 14px;
      left: 0px;
      top: 1px;
    }
  }

  .cabinet + .myLoans {
    margin-top: 30px;
  }

  .myLoans {
    @extend %btn;
    @extend %btn-link;
    width: auto;
    color: $main-black;
    max-height: 20px;
    line-height: 20px;
    text-decoration: none;
    position: relative;
    padding: 0 0 0 20px;

    &:focus,
    &:hover {
      color: $main-black;
      text-decoration: underline;
    }

    &::after {
      content: url('~@/assets/images/icon_list.svg');
      position: absolute;
      width: 15px;
      height: 14px;
      left: 0px;
      top: 1px;
    }
  }
}
.popup.multi {
  min-height: 130px;
}
</style>
