import { changePath } from '@/utils/commonFuncs';
import axios from 'axios';

const doRequestLogin = function(phone) {
  // loader
  this.isLoading = true;
  this.phoneLogin = phone;

  const _this = this;

  // REQUEST
  axios
    .post('/api/kabinet', { phone })
    .then(response => {
      try {
        if (
          Object.prototype.hasOwnProperty.call(
            response.data,
            'smsNotAllowed'
          ) &&
          response.data.smsNotAllowed
        ) {
          alert(this.config.dictionary.common.messages.repeatSendSMS);
        } else if (
          Object.prototype.hasOwnProperty.call(response.data, 'result_phone') &&
          !response.data.result_phone
        ) {
          _this.phoneNotFound = true;
          // alert(this.config.dictionary.common.messages.numberNotFound);
          // this.notAuth = true;
        } else if (response.data.status == 'resendSms') {
          alert(response.data.messages.msg);
        } else if (response.data.status == 'sendSMS') {
          // this.isLoginAction = bool;
          // this.isLoginAction = true;
          this.getme.code_hash = response.data.code_value;
          this.popupName = 'sms';
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    })
    .catch(error => {
      this.isLoading = false;
      throw new Error(error);
    });
};

// Send sms from modal (login page)
function applySmsQuestionLogin(code) {
  // loader
  this.isLoading = true;

  // data
  const data = {
    code,
    code_hash: this.getme.code_hash,
    csrf: this.getme.csrf,
    phone: this.phoneLogin
  };

  // REQUEST
  axios
    .post('/api/kabinet', data)
    .then(response => {
      try {
        if (
          Object.prototype.hasOwnProperty.call(response.data, 'wrongCode') &&
          response.data.wrongCode
        ) {
          this.getme.wrongCode = true;
        } else {
          changePath('lk');
        }

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    })
    .catch(error => {
      this.isLoading = false;
      throw new Error(error);
    });
}

export { doRequestLogin, applySmsQuestionLogin };
