<template>
  <div class="range-block__slider">
    <p>{{ rangeSlider.sumText }}</p>
    <div class="slider-summ">{{ valueToLocaleString(loanSum) }} ₽</div>
    <div class="slider-range">
      <div class="slider-range-wrapper slider-range-wrapper__summ">
        <div class="slider-range-wrapper__block">
          <span class="caption">{{ rangeSlider.sumText }}</span>
          <span class="quantity">{{ valueToLocaleString(loanSum) }} ₽</span>
        </div>
        <div class="slider-range__summ">
          <label>
            <input
              type="range"
              class="slider-range-input"
              :min="loanSumMin"
              :max="loanSumMax"
              :step="loanSumCalcStep"
              v-model="loanSum"
            />
          </label>
          <span class="slider-range-bar" ref="rangeBarSum">
            <span class="range-handle" ref="rangeHandleSum"></span>
            <span class="range-min"
              >{{ valueToLocaleString(loanSumMin) }} ₽</span
            >
            <span class="range-max"
              >{{ valueToLocaleString(loanSumMax) }} ₽</span
            >
            <span class="range-quantity" ref="rangeQuantitySum"></span>
          </span>
        </div>
      </div>

      <div class="slider-range-wrapper">
        <div class="slider-range-wrapper__block">
          <span class="caption">{{ rangeSlider.termText }}</span>
          <span class="quantity"
            >{{ valueToLocaleString(loanTerm) }} {{ daysCount }}</span
          >
        </div>
        <div class="slider-range__term">
          <label>
            <input
              type="range"
              class="slider-range-input"
              :min="loanTermMin"
              :max="loanTermMax"
              :step="loanTermCalcStep"
              v-model="loanTerm"
            />
          </label>
          <span class="slider-range-bar" ref="rangeBarTerm">
            <span class="range-handle" ref="rangeHandleTerm"></span>
            <span class="range-min">{{
              valueToLocaleString(loanTermMin)
            }}</span>
            <span class="range-max">{{
              valueToLocaleString(loanTermMax)
            }}</span>
            <span class="range-quantity" ref="rangeQuantityTerm"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { daysText } from '@/utils/commonFuncs';

export default {
  name: 'RangeSlider',
  props: {
    loanSumStart: { default: 10000 },
    loanSumCalcStep: { default: 1000 },
    loanSumMin: { default: 1000 },
    loanSumMax: { default: 100000 },
    loanTermStart: { default: 30 },
    loanTermCalcStep: { default: 1 },
    loanTermMin: { default: 10 },
    loanTermMax: { default: 90 },
    rangeSlider: { default: { daysText: [] } }
  },
  data() {
    return {
      loanSum: this.loanSumStart,
      loanTerm: this.loanTermStart,
      daysCount: this.rangeSlider.daysText[0]
    };
  },
  mounted() {
    let _this = this;

    window.onresize = function() {
      _this.changeSumRanges();
      _this.changeTermRanges();
    };

    _this.changeSumRanges();
    _this.changeTermRanges();
  },
  watch: {
    loanSumStart(value) {
      this.loanSum = value;
    },
    loanTermStart(value) {
      this.loanTerm = value;
    },
    loanSum(val) {
      Cookies.set('loanSum', val);
      this.changeSumRanges();
    },
    loanTerm(val) {
      Cookies.set('loanTerm', val);
      this.changeTermRanges();

      if (val) {
        this.daysCount = daysText(val, this.rangeSlider.daysText);
      }
    }
  },
  methods: {
    valueToLocaleString(val) {
      return Number(val).toLocaleString('ru-RU');
    },
    changeSumRanges() {
      let _this = this;

      const $rangeBar = _this.$refs.rangeBarSum;
      const $rangeHandle = _this.$refs.rangeHandleSum;
      const $rangeQuantity = _this.$refs.rangeQuantitySum;

      let koeff =
        ($rangeBar.offsetWidth - 25) /
        (parseInt(_this.loanSumMax) - parseInt(_this.loanSumMin));
      let val = _this.loanSum * koeff - _this.loanSumMin * koeff + 'px';

      $rangeHandle.style.left = val;
      $rangeQuantity.style.width = val;
    },
    changeTermRanges() {
      let _this = this;

      const $rangeBar = _this.$refs.rangeBarTerm;
      const $rangeHandle = _this.$refs.rangeHandleTerm;
      const $rangeQuantity = _this.$refs.rangeQuantityTerm;

      let koeff =
        ($rangeBar.offsetWidth - 25) /
        (parseInt(_this.loanTermMax) - parseInt(_this.loanTermMin));
      let val = _this.loanTerm * koeff - _this.loanTermMin * koeff + 'px';

      $rangeHandle.style.left = val;
      $rangeQuantity.style.width = val;
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/main.scss';

.range-block__slider {
  background-color: transparent;
  padding-top: 20px;

  @media (max-width: 768px) {
    padding-top: 0;
    margin-bottom: 55px;
  }

  p {
    color: $black-text;
    font-family: 'Montserrat-SemiBold';
    @extend %main-text;
    margin: 0;
    text-align: center;
    font-size: 18px;
  }

  .slider-summ {
    color: $black-text;
    font-size: 50px;
    line-height: 50px;
    font-family: 'Montserrat-SemiBold', 'Roboto', 'sans-serif';
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-bottom: 35px;
      font-size: 45px;
      line-height: 45px;
    }
  }

  .slider-range {
    &__summ,
    &__term {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 45px;

      input.slider-range-input {
        width: 100%;
        height: 30px;
        position: absolute;
        margin: 0;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }

      .slider-range-bar {
        background-color: #e1e1e1;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        display: block;
        height: 8px;
        position: relative;
        width: 100%;

        .range-handle {
          border-radius: 50%;
          position: absolute;
          top: 4px;
          -webkit-transition: box-shadow 0.1s ease-in-out;
          transition: box-shadow 0.1s ease-in-out;
          cursor: pointer;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 28px;
          height: 28px;
          border: 3px solid $green-base;
          background-color: $white;

          &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $green-base;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .range-max,
        .range-min {
          height: 15px;
          position: absolute;
          text-align: center;
          top: 15px;
          color: transparentize($black-text, 0.35);
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          font-family: 'Montserrat-SemiBold', 'Roboto', 'sans-serif';
        }

        .range-min {
          left: 0;
        }

        .range-max {
          right: 0;
        }

        .range-quantity {
          background: $green-base;
          background-image: linear-gradient(
            to right,
            rgba(93, 206, 173, 1) 80%,
            rgba(224, 247, 174, 1) 100%
          );
          border-radius: 15px;
          display: block;
          height: 100%;
          width: 0;
        }
      }
    }

    &__term {
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    .slider-range-wrapper__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .caption,
      .quantity {
        font-family: 'Montserrat-SemiBold', 'Roboto', 'sans-serif';
        font-size: 15px;
        line-height: 20px;
        color: $black-text;
        margin: 0;
      }
    }

    &-wrapper__summ {
      .slider-range-wrapper__block {
        display: none;
      }
    }
  }
}
</style>
