<template>
  <footer>
    <section class="contacts">
      <div class="contacts__item contacts__item_phone">
        <span class="title">
          {{ config.dictionary.common.footer.phoneText }}
        </span>
        <a
          class="description"
          :href="`tel:${config.siteConfig.contactPhone}`"
          >{{ config.siteConfig.contactPhone }}</a
        >
      </div>
      <div class="contacts__item contacts__item_email">
        <span class="title">
          {{ config.dictionary.common.footer.emailText }}
        </span>
        <a
          class="description"
          :href="`mailto:${config.siteConfig.contactEmail}`"
          >{{ config.siteConfig.contactEmail }}</a
        >
      </div>
      <div class="contacts__item contacts__item_adress">
        <span class="title">
          {{ config.dictionary.common.footer.addressText }}
        </span>
        <p class="description">{{ config.siteConfig.contactAddress }}</p>
      </div>
    </section>
    <section class="info" v-html="checkAcceptText"></section>
    <div class="product-label">
      &#174; {{ config.dictionary.common.footer.label }}
    </div>
  </footer>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'Footer',
  props: ['config', 'getme'],
  computed: {
    checkCookie() {
      return (
        Cookies.get('sbg-cpa') !== undefined || Cookies.get('CPA') !== undefined
      );
    },
    isAuth() {
      return Object.prototype.hasOwnProperty.call(this.getme.contactData, 'id');
    },
    checkAcceptText() {
      const _this = this;
      if (_this.checkCookie && _this.isAuth) {
        return _this.config.siteConfig.footerTextCpa;
      } else if (_this.checkCookie) {
        return _this.config.siteConfig.footerTextCpaFirst;
      } else {
        return _this.config.siteConfig.footerText;
      }
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/main.scss';
footer {
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 5px;
  }

  .contacts {
    max-width: 1400px;
    min-height: 290px;
    height: 100%;
    padding: 0 15px;
    box-sizing: content-box;
    margin: 0 auto;
    border-radius: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;

    @media (max-width: 768px) {
      max-width: 100%;
      min-height: 615px;
      padding: 0 20px;
      flex-direction: column;
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-start;
    }

    &__item {
      min-width: 140px;
      max-width: 270px;
      width: 100%;
      margin: 120px 20px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
      line-height: 25px;
      color: $black-text;
      text-align: center;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        width: 100px;
        height: 100px;
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
        border: none;
        border-radius: 50%;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.15);
      }

      &_phone:after {
        background-image: url('~@/assets/images/new/icon_phone_y.svg');
        background-size: 45px 45px;
      }

      &_email:after {
        background-image: url('~@/assets/images/new/icon_email_y.svg');
        background-size: 55px 42px;
      }

      &_adress:after {
        background-image: url('~@/assets/images/new/icon_address_y.svg');
        background-size: 52px 68px;
      }

      .title {
        font-family: 'Montserrat-Medium', 'Roboto', 'sans-serif';
        font-size: 20px;
        color: transparentize($black-text, 0.2);
      }

      .description {
        font-family: 'Montserrat-SemiBold', 'Roboto', 'sans-serif';
        font-size: 16px;
        color: $black-text;
        margin: 0;
      }
    }
  }

  .info {
    margin: 0 auto;
    box-sizing: border-box;
    max-width: 1170px;

    @media (max-width: 768px) {
      padding: 0 15px;
    }

    p {
      font-family: 'Montserrat-Medium', 'Roboto', 'sans-serif';
      font-size: 14px;
      color: $black-text;
      letter-spacing: 0;
      line-height: 25px;
      text-align: justify;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $green-dark;
        text-decoration: none;
        outline: none;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .product-label {
    font-family: 'Montserrat-Medium', 'Roboto', 'sans-serif';
    margin: 0 auto;
    max-width: 1170px;
    font-size: 12px;
    color: $black-text;
    opacity: 50%;
    letter-spacing: 0;
    line-height: 25px;
    padding: 20px 0 30px;

    @media (max-width: 768px) {
      max-width: 1170px;
      padding: 20px 15px 30px;
    }
  }
}
</style>
