<template>
  <section>
    <h3 class="title">
      {{ laonInfo.title }}
      <span class="timer_info" v-if="timerInfo !== null">
        Осталось <b>{{ timerInfo }}</b>
      </span>
    </h3>
    <div class="info_block">
      <div class="info__item info__item_summa">
        <h4 class="litle-title">{{ laonInfo.loanSum }}</h4>
        <p class="text">{{ loanSum.toLocaleString('ru-RU') }} ₽</p>
      </div>
      <div class="info__item info__item_data">
        <h4 class="litle-title">{{ laonInfo.loanTerm }}</h4>
        <p class="text">{{ loanTerm }} {{ daysCount }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { daysText } from '@/utils/commonFuncs';
import { timerPoll } from '@/utils/commonFuncs';

export default {
  name: 'LaonInfo',
  props: ['laonInfo', 'loanSum', 'loanTerm', 'daysText'],
  data() {
    return {
      timerInfo: '',
      daysCount: '',
      timerPoll
    };
  },
  watch: {
    daysText(arrText) {
      this.daysCount = daysText(this.loanTerm, arrText);
    }
  },
  mounted() {
    this.timerPoll(this);
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

// .title {
//   @extend %main-title-poll;
//   margin: 30px 0 50px;
//   text-align: center;

//   @media (max-width: 768px) {
//     font-size: 18px;
//     line-height: 30px;
//     margin: 0;
//   }

//   @media (max-width: 360px) {
//     padding: 0 20px;
//   }
// }

.info_block {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 70px;

  @media (max-width: 990px) {
    flex-direction: row;
    margin-top: 30px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
  }
}

.info__item {
  padding-top: 120px;
  position: relative;
  margin-bottom: 30px;

  @media (max-width: 990px) {
    padding-top: 95px;
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    padding-top: 80px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 990px) {
      width: 80px;
      height: 80px;
      background-size: 50px 50px;
    }

    @media (max-width: 480px) {
      width: 70px;
      height: 70px;
      background-size: 45px 45px;
    }
  }

  &_summa::before {
    background-image: url('~@/assets/images/new/icon_money_green.svg');

    // @media (max-width: 768px) {
    //   content: '';
    // }
  }

  &_data::before {
    background-image: url('~@/assets/images/new/icon_clock_green.svg');

    // @media (max-width: 768px) {
    //   content: '';
    // }
  }

  .litle-title {
    @extend %main-little-title;
    margin: 0;
    text-transform: none;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    font-family: 'Montserrat-Medium';

    @media (max-width: 480px) {
      line-height: 22px;
      font-size: 16px;
    }
  }

  .text {
    @extend %main-text;
    margin: 0;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    font-family: 'Montserrat-Medium';

    @media (max-width: 480px) {
      line-height: 22px;
      font-size: 16px;
    }
  }
}
</style>
