<template>
  <div class="loans-wrapper">
    <h2 class="title">{{ config.dictionary.final.title }}</h2>
    <p class="text">
      {{ config.dictionary.final.text }}
    </p>
    <div class="loans">
      <div v-for="offer of offers" :key="offer.img" class="loans__item">
        <LoanCard
          :isFeatured="offer.isFeatured"
          :logo="offer.logo"
          :link="offer.link"
          :org="offer.org"
          :title="offer.title"
          :header="offer.header"
          :chance="offer.chance"
          :subHeader="offer.subHeader"
          :common="config.dictionary.common"
          :final="config.dictionary.final"
        />
      </div>
    </div>
    <button class="button" v-if="displayBtn" @click.once="$emit('load-more')">
      {{ config.dictionary.final.more }}
    </button>
  </div>
</template>

<script>
import LoanCard from './components/LoanCard';

export default {
  name: 'Loans',
  props: ['config', 'offers', 'displayBtn', 'username'],
  components: {
    LoanCard
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.loans-wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto 40px;
  padding: 50px;

  @media (max-width: 960px) {
    padding: 30px 20px;
  }

  @media (max-width: 640px) {
    padding: 30px 10px;
  }

  @media (max-width: 480px) {
    padding: 30px 0px;
  }

  .title {
    @extend %main-title;
    margin: 0 auto 20px;
    text-align: center;
    color: $white;
    max-width: 500px;

    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 30px;
    }
  }

  .text {
    @extend %main-text;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Montserrat-SemiBold';
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: $white;

    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .loans {
    display: flex;
    flex-wrap: wrap;
    margin: 40px auto;
    max-width: 1200px;
    width: 100%;
    justify-content: center;

    @media (max-width: 640px) {
      margin: 120px auto 20px;
    }
  }

  .loans__item {
    max-width: 320px;
    width: 100%;
    margin: 0 20px 35px;

    @media (max-width: 640px) {
      margin: 0 0 35px;
      max-width: 100%;
    }
  }

  .button {
    @extend %btn-link-green;
    @extend %btn-small;
    display: block;
    max-width: 240px;
    height: 55px;
    margin: 0 auto;
    border-radius: 15px;
  }
}
</style>
