<template>
  <article>
    <h2 class="title">{{ common.popups.popupSMS.title }}</h2>
    <form @submit.prevent="submit">
      <div
        class="input-wrapper"
        :class="{
          invalid: wrongCode,
          valid: !wrongCode
        }"
      >
        <label class="label" for="code">{{
          common.popups.popupSMS.label
        }}</label>
        <IMaskComponent
          type="number"
          class="input"
          id="code"
          maxlength="5"
          inputmode="numeric"
          :mask="'00000'"
          v-model="code"
          autocomplete="off"
          @complete="onComplete"
          ref="input"
        />
        <small v-if="wrongCode">{{ common.fieldsError.errorSMS }}</small>
      </div>
      <button type="submit" class="button" :disabled="code.length !== 5">
        {{ common.btnsText.continue }}
      </button>
    </form>
  </article>
</template>

<script>
import { IMaskComponent } from 'vue-imask';

export default {
  name: 'PopupSMS',
  components: {
    IMaskComponent
  },
  props: ['common', 'wrongCode'],
  data() {
    return {
      code: ''
    };
  },
  methods: {
    onComplete() {
      this.submit();
    },
    inputFocus() {
      this.$refs.input.$el.focus();
    },
    submit() {
      this.$emit('apply-sms', this.code);
    }
  },
  mounted() {
    this.inputFocus();
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

form button {
  margin-top: 20px;
}

.title {
  @extend %popup-title;
  margin: 20px 0 10px;
}

.label {
  @extend %form-label;
}

.input {
  @extend %form-input;
  margin: 5px 0 0;
}

.button {
  @extend %btn;
  @extend %btn-green;
  max-width: 100%;
  margin-top: 35px;
}
</style>
