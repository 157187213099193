import Cookies from 'js-cookie';
import dayjs from 'dayjs';

// change path (go to url). Example (go to contact page => changePath('poll/contact'))
const changePath = function(path = '', event = false) {
  if (event) {
    event.preventDefault();
  }

  window.location.href = window.location.origin + '/' + path;
};

// change language
const changeLanguage = function(language) {
  let _this = this;

  if (language.toLowerCase() != _this.selectLanguage.toLowerCase()) {
    Cookies.set('selectLanguage', language);
    _this.selectLanguage = language;
    document.location.reload();
  }
};

const daysText = function(val, arrDays) {
  val = Number(val);
  let dec;

  return ((dec = val % 100) >= 11 && dec <= 19) ||
    (dec = val % 10) >= 5 ||
    dec === 0
    ? arrDays[2]
    : dec === 1
    ? arrDays[0]
    : arrDays[1];
};

const timerPoll = self => {
  let duration = 20 * 60;

  if (Cookies.get('duration')) {
    duration = dayjs(Cookies.get('duration'));
    duration = dayjs(duration).diff(dayjs());
    // duration = dayjs().$d.getTime() + duration;
    duration = Math.floor(duration / 1000);
  } else {
    let futureTime = dayjs().add(duration, 'second');
    // duration = futureTime.$d.getTime();
    Cookies.set('duration', futureTime, { expires: futureTime.$d });
  }

  let timer = duration;
  let minutes;
  let seconds;

  const timerInt = setInterval(() => {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    self.timerInfo = minutes + ':' + seconds;

    if (--timer < 0) {
      self.timerInfo = null;
      clearInterval(timerInt);
    }
  }, 1000);
};

const getmeDefault = {
  loanMinSum: 1000,
  loanMaxSum: 100000,
  loanCalcStep: 1000,
  loanSum: 10000,
  loanSumStart: 35000,
  loanTerm: 30,
  loanMinTerm: 10,
  loanMaxTerm: 90,
  loanTermCalcStep: 1,
  questionnaireId: null,
  isSubscribed: false,
  contactData: {
    lastname: '',
    firstname: '',
    patronymic: '',
    phone: '',
    email: '',
    birthday: '',
    gender: '0',
    addrcity: ''
  },
  passportData: {
    passportissuecode: '',
    passportnumber: '',
    passportseries: ''
  },
  csrf: '',
  publicApiKey: '',
  dadataApiKey: '',
  code_hash: '',
  code: '',
  userIP: '',
  accessToPage: true,
  wrongCode: false
};

const configDefault = {
  frontConfig: {},
  languages: {},
  selectLanguage: {},
  siteConfig: {},
  dictionary: {
    common: {
      laonInfo: {},
      footer: {},
      popups: {
        popupLogin: {},
        popupSkip: {},
        popupCongratulation: {},
        popupHeader: {},
        popupAcceptance: {},
        popupSMS: {}
      },
      rangeSlider: {
        daysText: []
      },
      fields: {},
      fieldsError: {},
      btnsText: {},
      messages: {}
    }
  }
};

const pagesDefault = {
  index: {
    firstScreen: {},
    secendScreen: {},
    thirdScreen: {},
    fourthScreen: {},
    fifthScreen: {},
    sixthScreen: {
      questions: []
    }
  },
  lite: {},
  primary: {},
  contact: {},
  passport: {},
  before: {},
  card: {},
  lk: {
    lkLinks: {}
  },
  final: {},
  unsubscribe: {
    noPhone: {}
  },
  ticket: {},
  nm: {}
};

function getDefualtConfig(page) {
  configDefault.dictionary[page] = pagesDefault[page];
  return configDefault;
}

function setCookiesTimeZone() {
  Cookies.set('sbg-tz', Intl.DateTimeFormat().resolvedOptions().timeZone);

  setTimeout(() => {
    if (Cookies.get('sbg-tz') == undefined || Cookies.get('sbg-tz') == null) {
      Cookies.set('sbg-tz', Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, 3000);
}

export {
  changePath,
  changeLanguage,
  daysText,
  getmeDefault,
  getDefualtConfig,
  setCookiesTimeZone,
  timerPoll
};
