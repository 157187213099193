<template>
  <div class="popup-shadow" @click.once.self="closePopup">
    <transition name="popup">
      <div v-if="isPopup" :class="isClose ? 'popup' : 'noPadPopup'">
        <button v-if="isClose" class="close" @click.once="closePopup"></button>
        <div class="content">
          <slot :name="slotName"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { toggleFreez } from '@/utils/helperApp';
export default {
  name: 'Popup',
  props: {
    slotName: {},
    closePopupWithAnimation: {},
    isClose: { default: true }
  },
  // props: ['slotName', 'closePopupWithAnimation'],
  data() {
    return {
      isPopup: false
    };
  },
  methods: {
    escCloseHandler(e) {
      if (e.key === 'Escape') {
        this.closePopup();
      }
    },
    closePopup() {
      if (this.isClose) {
        this.isPopup = false;
        setTimeout(() => {
          this.$emit('close-popup');
        }, 300);
      }
    }
  },
  watch: {
    closePopupWithAnimation(value) {
      if (value) {
        this.closePopup();
      }
    }
  },
  mounted() {
    this.isPopup = true;
    document.addEventListener('keydown', this.escCloseHandler);
  },
  beforeDestroy() {
    this.isPopup = false;
    document.removeEventListener('keydown', this.escCloseHandler);
  },
  destroyed() {
    toggleFreez(false);
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/main.scss';

.popup-shadow {
  height: 100%;
  width: 100%;
  background-color: transparentize($main-black, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

.popup {
  position: fixed;
  max-width: 600px;
  min-width: 335px;
  width: 100%;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 20px;
  padding: 20px 40px 30px;
  box-shadow: 5px 30px 55px rgba(51, 51, 51, 0.15);

  @media (max-width: 640px) {
    padding: 20px 20px 30px;
  }

  .close {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    width: 16px;
    height: 16px;
    background-color: $white;
    background-image: url('~@/assets/images/icon_close_small.svg');
  }
}

.noPadPopup {
  position: fixed;
  max-width: 600px;
  min-width: 335px;
  width: 100%;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 20px;
  box-shadow: 5px 30px 55px rgba(51, 51, 51, 0.15);
}

.popup-enter-active,
.popup-leave-active {
  transition: all 0.5s;
  top: 50vh;
  transform: translate(-50%, -50%);
}
.popup-enter,
.popup-leave-to {
  top: 0;
}
</style>
