import axios from 'axios';
import Cookies from 'js-cookie';
import { checkAccessPath } from './helpers';

const doRequestGetme = function(repeatRequest = false, path = '') {
  axios
    .get('/api/getme')
    .then(response => {
      // рефакторинг
      if (
        Array.isArray(response.data.questionnaire) &&
        !response.data.questionnaire.length
      ) {
        if (
          window.location.pathname.includes('final') &&
          Object.prototype.hasOwnProperty.call(this, 'accessToPage')
        ) {
          this.getme.accessToPage = false;
        }
      }

      response.data = { ...response.data, ...response.data.questionnaire };
      delete response.data.questionnaire;

      if (
        Object.prototype.hasOwnProperty.call(response.data, 'contactData') &&
        !Array.isArray(response.data.contactData)
      ) {
        if (Array.isArray(response.data.passportData)) {
          response.data.passportData = this.getme.passportData;
        }
      } else {
        response.data.contactData = this.getme.contactData;
        response.data.passportData = this.getme.passportData;
      }

      if (
        !Object.prototype.hasOwnProperty.call(response.data, 'questionnaireId')
      ) {
        response.data.questionnaireId = null;
      }

      try {
        if (
          !Object.prototype.hasOwnProperty.call(response.data, 'loanTerm') ||
          response.data.loanTerm == 0
        ) {
          if (Cookies.get('loanTerm')) {
            if (Number.isInteger(Number(Cookies.get('loanTerm')))) {
              response.data.loanTerm = Cookies.get('loanTerm');
            }
          } else {
            response.data.loanTerm = parseInt(
              this.config.frontConfig.loanTermStart
            );
          }
        }

        if (
          !Object.prototype.hasOwnProperty.call(response.data, 'loanSum') ||
          response.data.loanSum == 0
        ) {
          if (
            Cookies.get('loanSum') &&
            Number.isInteger(Number(Cookies.get('loanSum')))
          ) {
            response.data.loanSum = Cookies.get('loanSum');
          } else {
            response.data.loanSum = parseInt(
              this.config.frontConfig.loanSumStart
            );
          }
        }

        if (
          Object.prototype.hasOwnProperty.call(response.data.contactData, 'id')
        ) {
          // acceptance
          this.getme.acceptance = true;
          this.getme.acceptanceAutoPayment = true;
        }

        this.getme.loanSum = parseInt(response.data.loanSum);
        this.getme.loanTerm = parseInt(response.data.loanTerm);
        this.getme.questionnaireId = response.data.questionnaireId;
        this.getme.isSubscribed = response.data.isSubscribed;
        this.getme.reSubscribe = response.data.reSubscribe;
        this.getme.csrf = response.data.csrf;
        this.getme.userIP = response.data.ip;
        this.getme.contactData = response.data.contactData;
        this.getme.passportData = response.data.passportData;

        checkAccessPath(this.getme);

        // after getme
        this.afterRequestGetme();

        if (repeatRequest) {
          this.doRequest(
            {
              contactData: this.getme.contactData,
              passportData: this.getme.passportData,
              csrf: this.getme.csrf,
              loanSum: this.getme.loanSum,
              loanTerm: this.getme.loanTerm
            },
            path
          );
        }
      } catch (e) {
        // loader
        this.isLoading = false;
      }
    })
    .catch(error => {
      // loader
      this.isLoading = false;
      throw new Error(error);
    });
};

export default doRequestGetme;
